import styles from "./style.module.css";
import { useRouter as UseRouter } from "next/router";
import React, { useEffect as UseEffect, useRef as UseRef } from "react";
import { GetLanguageResource, IsNullOrEmpty } from "../../../libs/utils";
import { LanguageValueType, MenuSettings } from "../../../libs/enums";
import Link from "next/link";

var _menuId;

const LeftMenu = ({ menu, menus }) => {
  const router = UseRouter();
  const _leftMenu = UseRef();

  const onMenuClick = (e) => {
    const _listItem = e.nativeEvent.target.lastElementChild;
    const _icon = e.nativeEvent.target.firstElementChild;

    if (_listItem === null) return;

    if (Object.values(_icon)[1].id === "hasList") {
      _icon.classList.toggle(styles.iconChange);
    }

    _listItem.classList.toggle(styles.menuOpenOrClose);
  };

  const GetMenus = () => {
    return (
      <ul
        id="left-menu"
        className={`${styles.menu}`}
        ref={_leftMenu}
        role="menu">
        {menus &&
          menus
            .filter(
              (f) =>
                f.parentMenuId === parseInt(_menuId) &&
                !IsNullOrEmpty(f.languageResources)
            )
            .map((menu) => (
              <li
                role="menuitem"
                key={menu.uid}
                data-id={
                  GetLanguageResource(
                    menu.languageResources,
                    LanguageValueType.MENU
                  ).slug
                }
                onClick={(e) => {
                  onMenuClick(e);
                }}>
                {menu.url !== MenuSettings.PAGE_NO_ACTION ? (
                  <Link
                    href={`${
                      IsNullOrEmpty(menu.url) ||
                      menu.url == MenuSettings.PAGE ||
                      menu.url == MenuSettings.SUB_HOME_PAGE
                        ? `/${
                            GetLanguageResource(
                              menu.languageResources,
                              LanguageValueType.MENU
                            ).slug
                          }`
                        : menu.url
                    }`}>
                    <a>
                      {
                        GetLanguageResource(
                          menu.languageResources,
                          LanguageValueType.MENU
                        ).value
                      }

                      {menu.url === MenuSettings.SUB_HOME_PAGE && (
                        <b
                          className="fa-solid fa-up-right-from-square"
                          aria-hidden="true"></b>
                      )}
                    </a>
                  </Link>
                ) : (
                  GetLanguageResource(
                    menu.languageResources,
                    LanguageValueType.MENU
                  ).value
                )}

                {(menu.url === MenuSettings.PAGE_NO_ACTION ||
                  menu.url === MenuSettings.SUB_HOME_PAGE) && (
                  <i
                    id="hasList"
                    className="fa-solid fa-plus"
                    aria-label={`Aç/kapat`}
                    aria-expanded={false}></i>
                )}
                {onSubMenu(menu.menuId)}
              </li>
            ))}
      </ul>
    );
  };

  const onSubMenu = (menuId) => {
    return (
      !IsNullOrEmpty(menus.filter((f) => f.parentMenuId === menuId)[0]) && (
        <ul className={`${styles.menu}`} role="menu">
          {menus &&
            menus
              .filter(
                (f) =>
                  f.parentMenuId === menuId &&
                  !IsNullOrEmpty(f.languageResources)
              )
              .map((menu) => (
                <li
                  role="menuitem"
                  key={menu.uid}
                  data-id={
                    GetLanguageResource(
                      menu.languageResources,
                      LanguageValueType.MENU
                    ).slug
                  }>
                  {menu.url !== MenuSettings.PAGE_NO_ACTION ? (
                    <Link
                      href={`${
                        IsNullOrEmpty(menu.url) || menu.url == MenuSettings.PAGE
                          ? `/${
                              GetLanguageResource(
                                menu.languageResources,
                                LanguageValueType.MENU
                              ).slug
                            }`
                          : menu.url
                      }`}>
                      <a>
                        {
                          GetLanguageResource(
                            menu.languageResources,
                            LanguageValueType.MENU
                          ).value
                        }
                      </a>
                    </Link>
                  ) : (
                    <>
                      {
                        GetLanguageResource(
                          menu.languageResources,
                          LanguageValueType.MENU
                        ).value
                      }
                      {menu.url === MenuSettings.PAGE_NO_ACTION && (
                        <i
                          id="hasList"
                          className="fa-solid fa-plus "
                          aria-label={`Aç/kapat`}
                          aria-expanded={false}></i>
                      )}
                    </>
                  )}

                  {onSubMenu(menu.menuId, menu.uid)}
                </li>
              ))}
        </ul>
      )
    );
  };

  const GetTopMenuIdHandle = (parentMenuId = menu.parentMenuId) => {
    const result = menus && menus.filter((f) => f.menuId === parentMenuId)[0];
    result &&
      result.parentMenuId !== 0 &&
      GetTopMenuIdHandle(result.parentMenuId);

    if (result && result.parentMenuId === 0) {
      _menuId = result.menuId;
    }
  };

  const HandleOpenMenu = (item, slug) => {
    const _activeItem = document.querySelector(`[data-id='${slug}']`);
    var _topParent;

    if (IsNullOrEmpty(item) && !IsNullOrEmpty(_activeItem)) {
      _topParent = _activeItem.parentNode;

      document.querySelectorAll("#left-menu a").forEach((e) => {
        e.style.fontWeight = "normal";
      });

      _activeItem.lastElementChild.style.fontWeight = "var(--fontWeight)";
      _activeItem.lastElementChild.style.color = "var(--pttGreenColor)";
    } else {
      if (!IsNullOrEmpty(item)) {
        _topParent = item.parentNode;
      }
    }

    if (!IsNullOrEmpty(_topParent)) {
      if (_topParent.firstElementChild.id === "hasList") {
        _topParent.firstElementChild.classList.add(styles.iconChange);
      }

      if (_topParent.nodeName === "UL") {
        _topParent.classList.add(styles.menuOpenOrClose);
        document.getElementById("left-menu").style.border = "none";
      }

      HandleOpenMenu(_topParent, "");

      if (_topParent.id === "left-menu") return;
    }
  };

  UseEffect(() => {
    const questionMark = router.asPath.lastIndexOf("?");

    HandleOpenMenu(
      null,
      questionMark != -1
        ? router.asPath.substring(1, router.asPath.lastIndexOf("?"))
        : router.asPath.substring(1)
    );
  }, [menu]);

  return (
    <>
      {GetTopMenuIdHandle()}
      {GetMenus()}
    </>
  );
};

export default LeftMenu;
